import Link from 'next/link';
import styled, { css, keyframes } from 'styled-components';
import { white, black, darkGray, highlightGray } from 'styles/colors';
import { container } from 'styles/grid';
import { spacer8, spacer24, spacer48, spacer32 } from 'styles/spacers';
import { animations, fontWeights, navHeight } from 'styles/variables';
import { levels } from 'styles/z-index';
import { Icon } from 'components/atoms';

/* Styled Components */
export const Header = styled.header`
  background-color: ${({ theme }) => theme.backgroundColor || black};
  color: ${({ theme }) => theme.textColor};
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  height: ${navHeight.smToLg}rem;
  z-index: ${levels.persistent};

  -webkit-transition: opacity 200ms, visibility 300ms;
  transition: opacity 200ms, visibility 300ms;

  ${({ theme }) =>
    theme.showNav
      ? `
        opacity: 1;
        visibility: visible; `
      : `
      opacity: 0;
      visibility: hidden
  `}
`;

export const Container = styled.div`
  ${container}
  display: flex;
  align-items: center;
  gap: ${spacer24};
`;

export const Logo = styled(Link)`
  path {
    fill: ${({ theme }) => theme.logoColor};
  }
  div {
    display: flex;
  }
`;

export const MobileNavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .create-button {
    button {
      opacity: ${({ theme }) => (theme.isMobileNavOpen ? `0` : `1`)};
      visibility: ${({ theme }) =>
        theme.isMobileNavOpen ? `hidden` : `visible`};
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
      padding: 1rem 1.2rem;
    }
  }
`;

export const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacer24};
  width: 100%;
`;

export const MobileNavIconButton = styled.button`
  position: relative;
  height: 4.4rem;
  width: 4.4rem;
`;

export const MobileIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 4.4rem;
  width: 4.4rem;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  &.show {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    transition-delay: 0.6s;
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  }

  path {
    fill: ${({ theme }) => theme.textColor};
  }
`;

export const NavItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  position: relative;
`;

export const MainNavItemsContainer = styled.div`
  display: flex;
  gap: ${spacer24};
`;

/* MOBILE NAV COMPONENTS */
export const MobileBackdrop = styled.div`
  position: fixed;
  top: ${navHeight.smToLg}rem;
  left: 0;
  height: ${`calc(100vh - ${navHeight.smToLg}rem)`};
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColor || black};
  z-index: 5;
  opacity: ${({ theme }) => (theme.isMobileNavOpen ? `1` : `0`)};
  visibility: ${({ theme }) => (theme.isMobileNavOpen ? `visible` : `hidden`)};
  transform: ${({ theme }) =>
    theme.isMobileNavOpen
      ? `translateY(0)`
      : `translateY(calc(-100% - ${navHeight.smToLg}rem))`};
  transition: transform 1s ease-in-out, opacity 0.5s ease-in-out,
    visibility 1s ease-in-out;
`;

export const MobileBackdropContainer = styled.div`
  ${container}
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: ${spacer24};
  padding-bottom: ${spacer24};
  display: flex;
  flex-direction: column;
`;

export const MobileMainNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacer48};
  position: relative;
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const MobileCtaContainer = styled.div`
  width: 100%;
  margin-top: auto;
  button {
    justify-content: center;
    width: 100%;
  }
`;

/* SUB NAV COMPONENTS */
const NAV_TEXT_LINK = css`
  color: ${({ theme }) => theme.textColor};
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: ${props =>
    props.theme.isLower ? fontWeights.normal_p : fontWeights.extra_bold};

  &:hover,
  &:active {
    text-decoration: underline;
    text-underline-offset: 0.5rem;
    color: ${props => props.theme.highlightColor};
  }
`;

export const TextNavLink = styled(Link)`
  ${NAV_TEXT_LINK}
  color: ${({ theme, $isActive }) =>
    $isActive ? theme.highlightColor : theme.textColor};
`;

export const MobileTextNavLink = styled(Link)`
  ${NAV_TEXT_LINK}
  font-size: 3.2rem;
  line-height: 3.2rem;
  ${({ theme, $index }) =>
    theme.isMobileNavOpen &&
    css`
      animation: ${css`
          ${slideIn}`} 400ms;
      animation-fill-mode: both;
      animation-delay: calc(800ms + (100ms * ${$index}));
    `}
`;

export const LowerNavLink = styled(Link)`
  ${NAV_TEXT_LINK}
  font-weight: ${fontWeights.normal_p};
  white-space: nowrap;
  ${({ $isActive }) =>
    $isActive &&
    `
    color: ${white} !important;
    text-decoration: underline;
    text-underline-offset: 0.5rem;
  `}
`;

export const DropdownLink = styled.button`
  ${NAV_TEXT_LINK}
  display: flex;
  align-items: center;
  gap: ${spacer8};
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const MobileDropdownLink = styled(DropdownLink)`
  font-size: 2.4rem;
  line-height: 2.4rem;
  ${({ theme, $index }) =>
    theme.isMobileNavOpen &&
    css`
      animation: ${css`
          ${slideIn}`} 400ms;
      animation-fill-mode: both;
      animation-delay: calc(800ms + (100ms * ${$index}));
    `}
`;

export const Caret = styled(Icon)`
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  path {
    fill: ${({ theme }) => theme.textColor};
  }
  transition: transform ${animations.defaultTransition};
  transform: ${({ theme }) =>
    theme.subNavFocused ? `rotate(180deg)` : `unset`};
`;

export const MobileCaret = styled(Caret)`
  transform: ${({ theme }) =>
    theme.subNavFocused ? `rotate(90deg)` : `rotate(-90deg)`};
`;

const VISIBILITY_TRANSITION = css`
  opacity: ${({ theme }) => (theme.subNavFocused ? `1` : `0`)};
  visibility: ${({ theme }) => (theme.subNavFocused ? `visible` : `hidden`)};
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out,
    visibility 0.5s ease-in-out;
`;

export const DropdownContainer = styled.div`
  ${VISIBILITY_TRANSITION}
  position: absolute;
  top: ${spacer8};
  right: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  background-color: ${darkGray};
  border-radius: 1rem;
  transform: ${({ theme }) =>
    theme.subNavFocused ? `translateY(2rem)` : `unset`};
`;

export const DropdownMobileContainer = styled.div`
  ${VISIBILITY_TRANSITION}
  position: absolute;
  top: 0;
  left: ${spacer48};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacer32};
  background-color: ${({ theme }) => theme.backgroundColor || black};
  transform: ${({ theme }) =>
    theme.subNavFocused ? `translateX(-${spacer48})` : `unset`};
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const DropdownItem = styled(Link)`
  ${NAV_TEXT_LINK}
  padding: 1.6rem 2.4rem;
  border-radius: 1rem;
  font-weight: ${fontWeights.bold};
  color: ${white};
  &:hover,
  &:active {
    background-color: ${highlightGray};
    color: ${white};
    text-decoration: none;
  }

  ${({ theme, $index }) =>
    theme.subNavFocused &&
    css`
      animation: ${css`
          ${slideIn}`} 400ms;
      animation-fill-mode: both;
      animation-delay: calc(200ms + (100ms * ${$index}));
    `}
`;

export const DropdownMobileItem = styled(MobileTextNavLink)`
  font-size: 2.4rem !important;
  line-height: 2.4rem !important;
`;
