import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { Headline, Eyebrow, Paragraph, Button } from 'components/atoms';
import { Tag } from '@spotify-internal/encore-web';
import { useAppContext } from 'contexts/app-context';
import { useTranslation } from 'next-i18next';
import { ANALYTICS_TARGET } from 'constants/js-css-classes';
import kebabCase from 'lodash/kebabCase';

import { CTA_TYPES } from 'constants/cta-types';
import * as Styled from './Card.styled';

const HEADLINE_MAP = {
  oneUp: {
    tag: 'h2',
    styling: 'h2',
  },
  default: {
    tag: 'h6',
    styling: 'h6',
  },
};

/**
 * Card Component
 * Renders a card based on the following information provided
 * @param {Object} image Object with image src and alt, defined as url and description respectively
 * @param {String} title Title of the card
 * @param {String} eyebrow Eyebrow of the card
 * @param {String} tag Tag used to build the internal URL of the card /[tag]/[slug]
 * @param {String} slug Slug used to build the internal URL of the card /[tag]/[slug]
 * @param {String} modifier A modifier to use for style overrides.
 * @param {Function} onClick on click handler function
 * @param {String} paragraph Optional text to populate body paragraph in card
 * @param {String} ctaText Optional text to render custom text for CTA
 * @param {Array<String>} tags Optional array of strings to render footer tags
 * @param {String} type optional text to determine the cta type
 * @returns {ReactElement}
 */
const Card = ({
  image,
  title,
  eyebrow,
  tag,
  slug,
  modifier,
  onClick,
  paragraph,
  ctaText,
  tags,
  type,
}) => {
  const { t } = useTranslation();
  const [{ locale }] = useAppContext();
  const headlineProps = HEADLINE_MAP[modifier] || HEADLINE_MAP.default;
  const CardCTA =
    Styled.COMPONENT_MAP.CTA[modifier] || Styled.COMPONENT_MAP.CTA.default;

  const href = slug !== '' ? '/[locale]/[tag]/[slug]' : '/[locale]/[tag]';

  return (
    <ThemeProvider theme={{ modifier }}>
      <Styled.Card>
        <Styled.Cta
          href={href}
          asLink={`/${locale}/${tag}/${slug}`}
          type={CTA_TYPES.WRAPPER}
          onClick={onClick}
        />
        <Styled.Image
          data-src={image.url}
          aria-label={image.description || ''}
        />
        <Styled.Content>
          {eyebrow && (
            <Styled.EyebrowContainer>
              <Eyebrow>{eyebrow}</Eyebrow>
            </Styled.EyebrowContainer>
          )}
          <Styled.HeadlineContainer>
            <Headline {...headlineProps} text={title} />
          </Styled.HeadlineContainer>
          {paragraph && (
            <Styled.ParagraphContainer>
              <Paragraph>{paragraph}</Paragraph>
            </Styled.ParagraphContainer>
          )}
          {type === 'curatedList' ? (
            <Button type="roundRightArrow" className={ANALYTICS_TARGET} />
          ) : (
            <CardCTA className={ANALYTICS_TARGET}>
              {ctaText || t('readMore')}
            </CardCTA>
          )}
          {tags && tags.length > 0 && (
            <Styled.TagsContainer>
              {tags.map(tagText => (
                <Tag key={kebabCase(tagText)}>{tagText}</Tag>
              ))}
            </Styled.TagsContainer>
          )}
        </Styled.Content>
      </Styled.Card>
    </ThemeProvider>
  );
};

Card.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  eyebrow: PropTypes.string,
  tag: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  ctaText: PropTypes.string,
  tags: PropTypes.arrayOf(String),
  modifier: PropTypes.string,
  onClick: PropTypes.any,
  type: PropTypes.string,
};

export default Card;
