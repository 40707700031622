import styled, { css } from 'styled-components';
import { animations, fontWeights } from 'styles/variables';
import { minWidth } from 'styles/media-queries';
import { black, white, white20OpacityOnBlack } from 'styles/colors';
import Link from 'next/link';
import { opacityWhite30 } from '@spotify-internal/encore-web';

export const CtaButton = css`
  border-radius: 5rem;
  border-style: solid;
  border-width: 0.1rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1.2rem 2.4rem;

  font-size: 1.4rem;
  height: 4rem;
  width: fit-content;
  line-height: 1.54rem;
  font-weight: ${fontWeights.extra_bold};
  text-decoration: none;
  ${minWidth.lg`
    font-size: 1.6rem;
    line-height: 1.76rem;
    margin-right: 1.25rem;
  `}

  .cta-icon {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
    }
  }

  path {
    transition: fill ${animations.defaultTransition};
  }
  transition: background-color ${animations.defaultTransition},
    border-color ${animations.defaultTransition},
    color ${animations.defaultTransition};
`;

// black text on white background
const blackOnWhite = css`
  background-color: ${white};
  border-color: ${white};
  color: ${black};
  path {
    fill: ${black};
  }
`;

// white text on transparent background
const whiteOnTransparent = css`
  background-color: transparent;
  border-color: ${white};
  color: ${white};
  path {
    fill: ${white};
  }
`;

export const ctaHoverState = css`
  background-color: ${white20OpacityOnBlack};
  border-color: ${white20OpacityOnBlack};
  color: ${white};
  path {
    fill: ${white};
  }
`;

export const PrimaryCta = styled(Link)`
  ${CtaButton}
  ${blackOnWhite}

  &:active {
    ${ctaHoverState}
  }
  &:hover {
    ${ctaHoverState}
  }
`;

export const SecondaryCta = styled(Link)`
  ${CtaButton}
  ${whiteOnTransparent}

  &:active {
    ${ctaHoverState}
  }
  &:hover {
    ${ctaHoverState}
  }
`;

export const Button = styled.button`
  ${CtaButton}
  ${blackOnWhite}

  &:active {
    ${ctaHoverState}
  }
  &:hover {
    ${ctaHoverState}
  }
`;

export const textLinkHoverState = css`
  color: ${white20OpacityOnBlack};
  path {
    fill: ${white20OpacityOnBlack};
  }
`;

export const TextLink = styled(Link)`
  color: inherit;
  path {
    fill: inherit;
  }
  transition: color ${animations.defaultTransition},
    text-decoration ${animations.defaultTransition};
  text-decoration-thickness: 0.05rem;
  &:active {
    ${textLinkHoverState}
  }
  &:hover {
    ${textLinkHoverState}
  }
`;

export const Wrapper = styled(Link)`
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
`;

export const ArticleTag = styled(Link)`
  border-radius: 5rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;

  font-size: 1.25rem;
  height: 3rem;
  line-height: 1.54rem;
  font-weight: ${fontWeights.bold};
  text-decoration: none;

  background-color: ${opacityWhite30};
  color: ${black};

  &:active {
    ${ctaHoverState}
  }
  &:hover {
    ${ctaHoverState}
  }

  transition: background-color ${animations.defaultTransition},
    color ${animations.defaultTransition};
`;

// Basic states
export const multiple = css`
  margin-right: 1.6rem;
  margin-bottom: 1.6rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }

  ${minWidth.md`
    margin-right: 1.6rem;
    max-width: 40.8rem;
    width: auto;
  `}
`;
