import styled from 'styled-components';
import Link from 'next/link';
import { levels } from 'styles/z-index';
import { solidBlack0, spotifyGreen157 } from '@spotify-internal/encore-web';

export const SkipToMain = styled(Link)`
  background-color: ${spotifyGreen157};
  border-radius: 0;
  border: none;
  color: ${solidBlack0};
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  z-index: ${levels.persistent};

  &:focus {
    opacity: 1;
    transform: translateX(0);
  }
`;
