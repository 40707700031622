import styled from 'styled-components';

import {
  Input as InputComponent,
  Checkbox as CheckboxComponent,
  Button,
} from 'components/atoms';
import { minWidth } from 'styles/media-queries';
import { gray50 } from '@spotify-internal/encore-web';
import { fontWeights } from 'styles/variables';

export const Input = styled(InputComponent)`
  color: ${props => props.color || 'inherit'};

  input::placeholder {
    color: ${props => props.placeHolderColor || gray50};
  }

  input::-webkit-input-placeholder {
    color: ${props => props.placeHolderColor || gray50};
  }

  input::-ms-input-placeholder {
    color: ${props => props.placeHolderColor || gray50};
  }

  input:-moz-placeholder {
    color: ${props => props.placeHolderColor || gray50};
    opacity: 1 !important;
  }

  input::-moz-placeholder {
    color: ${props => props.placeHolderColor || gray50};
    opacity: 1 !important;
  }
`;

export const Checkbox = styled(CheckboxComponent)`
  color: ${props => props.color || 'inherit'};
  margin-bottom: 2.4rem;

  ${minWidth.lg`
    margin-bottom: 3.2rem;
  `}
`;

export const Submit = styled(Button)`
  max-width: 100%;
  width: 100%;
  font-weight: ${fontWeights.extra_bold};

  ${minWidth.lg`
    width: auto;
  `}
`;
