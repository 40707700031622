import { css } from 'styled-components';

export const ACTIVATE_ANIMATION_CLASS = 'activate-animation';
export const SETUP_ANIMATION_CLASS = 'setup-animation';

export const fadeContentSlideUp = {
  setup: css`
    opacity: 0;
    transform: translateY(50px);
  `,
  play: css`
    animation: fadeContentSlideUp 500ms 0s ease-in-out forwards;
  `,
};

export const fadeContentSlideDown = {
  setup: css`
    opacity: 0;
    transform: translateY(0);
  `,
  play: css`
    animation: fadeContentSlideDown 500ms 0s ease-out forwards;
  `,
};

export const fadeContentSlideBackUp = {
  setup: css`
    opacity: 0;
    transform: translateY(0);
  `,
  play: css`
    animation: fadeContentSlideBackUp 500ms 0s ease-in forwards;
  `,
};

const fadeContentSlideUpFrames = css`
  @keyframes fadeContentSlideUp {
    from {
      opacity: 0;
      transform: translateY(5%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const fadeContentSlideDownFrames = css`
  @keyframes fadeContentSlideDown {
    from {
      opacity: 0;
      transform: translateY(-5%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const fadeContentSlideBackUpFrames = css`
  @keyframes fadeContentSlideBackUp {
    from {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(-5%);
    }
  }
`;

export const showUpFromLeft = {
  setup: css`
    transform: translate(-50%, 100%);
  `,
  play: css`
    animation: showUpFromLeft 500ms 0s ease-in-out forwards;
  `,
};
const showUpFromLeftFrames = css`
  @keyframes showUpFromLeft {
    from {
      transform: translate(-50%, 100%);
    }

    to {
      transform: translate(0, 0);
    }
  }
`;

const rotateFrames = css`
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const animationKeyFrames = css`
  ${fadeContentSlideDownFrames}
  ${fadeContentSlideUpFrames}
  ${showUpFromLeftFrames}
  ${rotateFrames}
  ${fadeContentSlideBackUpFrames}
`;

export default animationKeyFrames;
