import { gql } from '@apollo/client';

import { USE_CONTENT_PREVIEW_FILTER } from 'constants/query';
import { getLocalizedField } from 'utils/get-localized-field';
import { DYNAMIC_FRAGMENT_LITERALS } from 'queries/fragment-literals';
import {
  COMPONENT_FRAGMENTS,
  MODULE_FRAGMENTS,
  DYNAMIC_FRAGMENTS,
} from 'queries/fragments';
import { FRAGMENTS } from './navigation';

const getLandingFilter = () =>
  USE_CONTENT_PREVIEW_FILTER
    ? `{
          OR: [
            { navigationTag: { slug: $tagSlug } }
            { navigationTag: { sys: { id: $tagSlug } } }
          ]
        }`
    : `{ navigationTag: { slug: $tagSlug } }`;

// Note that while landing pages do not have field-level localization, we must pass $locale to gql for curated list to work correctly.
// A curated list can contain a global detail page, which requires $locale.
const PAGE_LANDING_QUERY = {
  body: ({
    collection = 'pageLandingCollection',
    filter = getLandingFilter(),
  }) => gql`
    query LandingPage($preview: Boolean = false, $tagSlug: String!, $locale: String!) {
      ${collection}(
        preview: $preview
        where: ${filter}
        locale: $locale
        limit: 1
      ) {
        items {
          meta {
            ...MetaFields
          }
          pageIndexation
          hero {
            ...HeroFields
          }
          subhead
          introText
          hideFooterLinks
          hideNavigationLinks
          hideContactForm
          lowerNavigation {
            slug
            navigationItemsCollection {
              items {
                type: __typename
                ... on NavigationTag {
                  ...NavigationTag
                }
                ... on Dropdown {
                  ...Dropdown
                }
              }
            }
          }
          backgroundColor
        }
      }
    }
    ${COMPONENT_FRAGMENTS.CTA}
    ${COMPONENT_FRAGMENTS.META}
    ${COMPONENT_FRAGMENTS.HERO}
    ${COMPONENT_FRAGMENTS.IMAGE}
    ${FRAGMENTS.NAVIGATION_TAG}
    ${FRAGMENTS.LINK}
    ${FRAGMENTS.DROPDOWN}
  `,
  modules: ({
    collection = 'pageLandingCollection',
    collectionLocale = '',
    filter = getLandingFilter(),
  }) => gql`
    query LandingPage($preview: Boolean = false, $tagSlug: String!, $locale: String!) {
      ${collection}(
        preview: $preview
        where: ${filter}
        locale: $locale
        limit: 1
      ) {
        items {
          modulesCollection {
            items {
              ... on ModuleCatalog {
                ...Catalog
              }
              ... on ModuleAudio {
                ...Audio
              }
              ... on ModuleCuratedListGroup {
                ${DYNAMIC_FRAGMENT_LITERALS.CURATED_LIST_GROUP(
                  getLocalizedField(collectionLocale),
                )}
              }
              ... on ModuleSpotifyPlayer {
                ...SpotifyPlayer
              }
              ... on ModuleFeatureCardContainer {
                ...FeatureCardContainer
              }
              ... on ModuleFeatureCard {
                ...FeatureCard
              }
              ... on ModuleInlineForm {
                ...InlineForm
              }
              ... on ModuleTopicCarousel {
                ...TopicCarousel
              }
              ... on ModuleTopic {
                ...Topic
              }
              ... on ModuleJumpLinkGroup {
                ...JumpLinkGroup
              }
              ... on ModuleMarkdownText {
                ...MarkdownText
              }
              ... on ModuleVideo {
                ...Video
              }
              ... on ModuleInlineCard {
                ...InlineCard
              }
              ... on ModuleStats {
                ...Stats
              }
              ... on ModuleAdExperiences {
                ...AdExperiences
              }
              ... on ModuleAccordion {
                ...Accordion
              }
              ... on ModuleChromeFeatureCard {
                ...ChromeFeatureCard
              }
              ... on ModuleVideoFeatureCard {
                ...VideoFeatureCard
              }
              ... on ModuleTaggedContentGroup {
                ...TaggedContentGroup
              }
              ... on ModuleTabbedCuratedList {
                ...TabbedCuratedList
              }
              ... on ModuleTrendGrid {
                ...TrendGrid
              }
              ... on ModuleSliderTab {
                ...SliderTab
              }
              ... on ModuleSplitView {
                ...SplitView
              }
              ... on ModuleTileCarousel {
                ...TileCarousel
              }
              ... on ModuleList {
                ...List
              }
              ... on ModuleGallery {
                ...Gallery
              }
              ... on ModuleSectionDivider {
                ...SectionDivider
              }
              ... on ModuleFootnotes {
                ...Footnotes
              }
            }
          }
        }
      }
    }
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_DETAIL(
      getLocalizedField(collectionLocale),
    )}
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_LANDING(
      getLocalizedField(collectionLocale),
    )}
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_GLOBAL}
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_TRENDS}
    ${COMPONENT_FRAGMENTS.CTA}
    ${COMPONENT_FRAGMENTS.IMAGE}
    ${MODULE_FRAGMENTS.IMAGE}
    ${MODULE_FRAGMENTS.CATALOG}
    ${MODULE_FRAGMENTS.AUDIO}
    ${MODULE_FRAGMENTS.SPOTIFY_PLAYER}
    ${MODULE_FRAGMENTS.FEATURE_CARD_CONTAINER}
    ${MODULE_FRAGMENTS.FEATURE_CARD}
    ${MODULE_FRAGMENTS.FEATURE_CARD_WITH_FORM}
    ${MODULE_FRAGMENTS.INLINE_FORM}
    ${MODULE_FRAGMENTS.TOPIC_CAROUSEL}
    ${MODULE_FRAGMENTS.TOPIC}
    ${MODULE_FRAGMENTS.JUMP_LINK_GROUP}
    ${MODULE_FRAGMENTS.MARKDOWN_TEXT}
    ${MODULE_FRAGMENTS.VIDEO}
    ${MODULE_FRAGMENTS.INLINE_CARD}
    ${MODULE_FRAGMENTS.STATS}
    ${COMPONENT_FRAGMENTS.STAT_CARD}
    ${COMPONENT_FRAGMENTS.CTA_CARD}
    ${MODULE_FRAGMENTS.AD_EXPERIENCES}
    ${MODULE_FRAGMENTS.ACCORDION}
    ${MODULE_FRAGMENTS.CHROME_FEATURE_CARD}
    ${MODULE_FRAGMENTS.VIDEO_FEATURE_CARD}
    ${MODULE_FRAGMENTS.TREND_GRID}
    ${MODULE_FRAGMENTS.QUOTE}
    ${COMPONENT_FRAGMENTS.CONTENT_TILE_NO_TRENDS}
    ${MODULE_FRAGMENTS.TAGGED_CONTENT_GROUP}
    ${MODULE_FRAGMENTS.CONTENT_CARD_CURATED_LIST}
    ${COMPONENT_FRAGMENTS.CONTENT_CARD}
    ${MODULE_FRAGMENTS.TABBED_CURATED_LIST}
    ${MODULE_FRAGMENTS.SLIDER_TAB}
    ${MODULE_FRAGMENTS.SPLIT_VIEW}
    ${MODULE_FRAGMENTS.TILE_CAROUSEL}
    ${MODULE_FRAGMENTS.LIST}
    ${COMPONENT_FRAGMENTS.LIST_ITEM}
    ${MODULE_FRAGMENTS.GALLERY}
    ${MODULE_FRAGMENTS.SECTION_DIVIDER}
    ${MODULE_FRAGMENTS.FOOTNOTES}
  `,
};

export default PAGE_LANDING_QUERY;
