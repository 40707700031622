import React from 'react';
import PropTypes, { any } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import {
  CONTENT_TILE_THEMES,
  getContentTileTheme,
} from 'utils/get-theme-selector';
import { getLinkProps } from 'utils/get-link-props';
import { CTA_TYPES } from 'constants/cta-types';
import { Cta } from 'components/atoms';
import * as Styled from './ContentTile.styled';

/**
 * Renders a content tile
 * @param {Object} tile tile object
 * @param {Function} handleClick click handler
 * @param {string|null} className The component class name.
 * @returns {ReactElement}
 */
const ContentTile = ({ tile, handleClick, className }) => {
  const {
    title,
    description,
    image,
    backgroundColor,
    textColor,
    eyebrow,
    themeSelector,
    cta,
  } = tile;
  const themeType = getContentTileTheme(themeSelector?.toLowerCase());

  const getTileByType = () => {
    switch (themeType) {
      case CONTENT_TILE_THEMES.QUOTE:
        return (
          <Styled.ContentTile className={className} noAspectLock={false}>
            <Styled.QuoteTitleContainer>
              <Styled.QuotationMark tag="h4" styling="h1" text={'__"__'} />
              <Styled.QuoteText tag="h5" styling="h5" text={`__${title}__`} />
            </Styled.QuoteTitleContainer>
            <Styled.QuoteSpeaker styling="P1_CAPTION">
              {description}
            </Styled.QuoteSpeaker>
          </Styled.ContentTile>
        );
      case CONTENT_TILE_THEMES.IMAGE:
        return (
          <Styled.ContentTile
            data-src={image?.url}
            className={className}
            noAspectLock={false}
          />
        );
      case CONTENT_TILE_THEMES.CONTENT:
        return (
          <Styled.ContentTile
            onClick={handleClick}
            className={`${className}`}
            noAspectLock={false}
          >
            {cta && <Cta {...getLinkProps(cta.url)} type={CTA_TYPES.WRAPPER} />}
            <Styled.EyebrowContainer data-src={image?.url}>
              {eyebrow && <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow>}
            </Styled.EyebrowContainer>
            <Styled.TitleContainer>
              <Styled.TitleText
                tag="h3"
                styling="h3"
                text={title}
                className="tile-title"
              />
              {(cta || handleClick) && (
                <Styled.ButtonCta
                  type="roundRightArrow"
                  className="button-arrow"
                />
              )}
            </Styled.TitleContainer>
          </Styled.ContentTile>
        );
      case CONTENT_TILE_THEMES.TREND:
        return (
          <Styled.ContentTile
            onClick={handleClick}
            className={`${className} trend-tile`}
            noAspectLock={false}
          >
            <Styled.EyebrowContainer data-src={image?.url} />
            <Styled.TitleContainer>
              <Styled.TitleText tag="h5" styling="h5" text={title} />
            </Styled.TitleContainer>
          </Styled.ContentTile>
        );
      case CONTENT_TILE_THEMES.TOPIC:
        return (
          <a href={getLinkProps(cta?.url).asLink}>
            <Styled.ContentTile
              className={className}
              noAspectLock
              themeType={themeType}
            >
              <Styled.FixedImageContainer data-src={image?.url} />
              <Styled.TopicContainer>
                <Styled.TitleText tag="h4" styling="h4" text={`__${title}__`} />
                <Styled.Description styling="p1">
                  {description}
                </Styled.Description>
                {cta && (
                  <Styled.ButtonCta
                    type="roundRightArrow"
                    className="button-arrow"
                    light
                  />
                )}
              </Styled.TopicContainer>
            </Styled.ContentTile>
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <ThemeProvider
      theme={{
        backgroundColor,
        textColor,
        hasImage: image,
      }}
    >
      {getTileByType()}
    </ThemeProvider>
  );
};

ContentTile.propTypes = {
  /**
   * The tile component
   */
  tile: PropTypes.objectOf(any),
  /**
   * click tile handler
   */
  handleClick: PropTypes.func,
  /**
   * Default className prop
   */
  className: PropTypes.string,
};

export default ContentTile;
