import styled from 'styled-components';

import { maxWidth, minWidth } from 'styles/media-queries';

import { container } from 'styles/grid';
import { sectionMargins, spacer16 } from 'styles/spacers';
import { StatCard } from 'components/molecules';
import { black } from 'styles/colors';
import { fontWeights } from 'styles/variables';

export const StatSlideshow = styled.section`
  background-color: ${black};
  position: relative;
  overflow-x: clip;
  overflow-y: clip;
  ${sectionMargins}
`;
export const Container = styled.section`
  padding-top: 28rem;
  padding-bottom: 50rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 64.87px;
  --webkit-transition: transform 100ms ease-in-out forwards;
  transition: transform 100ms ease-in-out;

  ${maxWidth.sm`
   padding-bottom: 49rem;
  `}

  ${minWidth.md`
    padding-top: 30rem;
  `}

  .slide {
    display: block;
    position: absolute;
    transition: transform 100ms ease-in-out;
    will-change: transform;

    h3:first-of-type {
      width: 60%;
      font-weight: ${fontWeights.extra_bold};
      font-stretch: expanded;
      font-size: 4.2rem;
      line-height: 110%;
      margin-bottom: -2rem;
      margin-top: -1rem;
    }

    h3:last-of-type {
      font-stretch: condensed;
      font-size: 5.4rem;
      line-height: 110%;
      font-weight: ${fontWeights.normal};
    }

    ${maxWidth.sm`
      h3:first-of-type {
        margin-top: -2rem;
        font-size: 3.2rem;
      }

      h3:last-of-type {
        font-size: 4rem;
      }

      p {
        font-size: 1.2rem;
      }
  `}
  }

  .slide.positionAt0 {
    opacity: 0;
    transform: rotate(-30deg) translate(-1400px, -100px);
  }

  .slide.positionAt1 {
    transform: rotate(-30deg) translate(-905px, -230px);
  }

  .slide.positionAt2 {
    transform: rotate(-15deg) translate(-480px, -50px);

    ${maxWidth.sm`
      transform: rotate(-15deg) translate(-335px, -80px);
    `}
  }

  .slide.positionAt3 {
    transform: scale(1.3) translate(0, -40px);
  }

  .slide.positionAt4 {
    transform: rotate(15deg) translate(480px, -50px);

    ${maxWidth.sm`
       transform: rotate(15deg) translate(335px, -80px);
    `}
  }

  .slide.positionAt5 {
    transform: rotate(30deg) translate(905px, -230px);
  }

  .slide.positionAt6 {
    opacity: 0;
    transform: rotate(30deg) translate(1400px, -100px);
  }

  .slide.rotate-left.positionAt0 {
    animation: oneToZero 2s ease-out;
    animation-fill-mode: forwards;
  }

  .slide.rotate-left.positionAt1 {
    animation: twoToOne 1.5s ease-out;
    animation-fill-mode: forwards;

    ${maxWidth.sm`
      animation: twoToOneSmall 1.5s ease-out;
      animation-fill-mode: forwards;
    `}
  }

  .slide.rotate-left.positionAt2 {
    animation: threeToTwo 1.5s ease-out;
    animation-fill-mode: forwards;

    ${maxWidth.sm`
      animation: threeToTwoSmall 1.5s ease-out;
      animation-fill-mode: forwards;
    `}
  }

  .slide.rotate-left.positionAt3 {
    animation: fourToThree 1.5s ease-out;
    animation-fill-mode: forwards;

    ${maxWidth.sm`
      animation: fourToThreeSmall 1.5s ease-out;
      animation-fill-mode: forwards;
    `}
  }

  .slide.rotate-left.positionAt4 {
    animation: fiveToFour 1.5s ease-out;
    animation-fill-mode: forwards;

    ${maxWidth.sm`
      animation: fiveToFourSmall 1.5s ease-out;
      animation-fill-mode: forwards;
    `}
  }

  .slide.rotate-left.positionAt5 {
    animation: sixToFive 1.5s ease-out;
    animation-fill-mode: forwards;
  }

  .slide.rotate-right.positionAt1 {
    animation: zeroToOne 1.5s ease-out;
    animation-fill-mode: forwards;
  }

  .slide.rotate-right.positionAt2 {
    animation: oneToTwo 1.5s ease-out;
    animation-fill-mode: forwards;

    ${maxWidth.sm`
      animation: oneToTwoSmall 1.5s ease-out;
      animation-fill-mode: forwards;
    `}
  }

  .slide.rotate-right.positionAt3 {
    animation: twoToThree 1.5s ease-out;
    animation-fill-mode: forwards;

    ${maxWidth.sm`
      animation: twoToThreeSmall 1.5s ease-out;
      animation-fill-mode: forwards;
    `}
  }

  .slide.rotate-right.positionAt4 {
    animation: threeToFour 1.5s ease-out;
    animation-fill-mode: forwards;

    ${maxWidth.sm`
      animation: threeToFourSmall 1.5s ease-out;
      animation-fill-mode: forwards;
    `}
  }

  .slide.rotate-right.positionAt5 {
    animation: fourToFive 1.5s ease-out;
    animation-fill-mode: forwards;

    ${maxWidth.sm`
      animation: fourToFiveSmall 1.5s ease-out;
      animation-fill-mode: forwards;
    `}
  }

  .slide.rotate-right.positionAt6 {
    animation: fiveToSix 3s ease-out;
    animation-fill-mode: forwards;
  }

  @keyframes zeroToOne {
    from {
      transform: rotate(-30deg) translate(-1400px, -110px);
    }
    to {
      transform: rotate(-30deg) translate(-905px, -230px);
    }
  }

  @keyframes oneToTwo {
    from {
      transform: rotate(-30deg) translate(-905px, -230px);
    }
    to {
      transform: rotate(-15deg) translate(-480px, -50px);
    }
  }

  @keyframes oneToTwoSmall {
    from {
      transform: rotate(-30deg) translate(-905px, -230px);
    }
    to {
      transform: rotate(-15deg) translate(-335px, -80px);
    }
  }

  @keyframes twoToThree {
    from {
      transform: rotate(-15deg) translate(-480px, -50px);
    }
    to {
      transform: scale(1.3) translate(0, -40px);
    }
  }

  @keyframes twoToThreeSmall {
    from {
      transform: rotate(-15deg) translate(-335px, -80px);
    }
    to {
      transform: scale(1.3) translate(0, -40px);
    }
  }

  @keyframes threeToFour {
    from {
      transform: scale(1.3) translate(0, -40px);
    }
    to {
      transform: rotate(15deg) translate(480px, -50px);
    }
  }

  @keyframes threeToFourSmall {
    from {
      transform: scale(1.3) translate(0, -40px);
    }
    to {
      transform: rotate(15deg) translate(335px, -80px);
    }
  }

  @keyframes fourToFive {
    from {
      transform: rotate(15deg) translate(480px, -50px);
    }
    to {
      transform: rotate(30deg) translate(905px, -230px);
    }
  }

  @keyframes fourToFiveSmall {
    from {
      transform: rotate(15deg) translate(335px, -80px);
    }
    to {
      transform: rotate(30deg) translate(905px, -230px);
    }
  }

  @keyframes fiveToSix {
    from {
      transform: rotate(30deg) translate(905px, -230px);
    }
    to {
      transform: rotate(30deg) translate(1400px, -100px);
    }
  }

  @keyframes oneToZero {
    from {
      transform: rotate(-30deg) translate(-905px, -230px);
    }
    to {
      transform: rotate(-30deg) translate(-1400px, -100px);
    }
  }

  @keyframes twoToOne {
    from {
      transform: rotate(-15deg) translate(-480px, -50px);
    }
    to {
      transform: rotate(-30deg) translate(-905px, -230px);
    }
  }

  @keyframes twoToOneSmall {
    from {
      transform: rotate(-15deg) translate(-335px, -80px);
    }
    to {
      transform: rotate(-30deg) translate(-905px, -230px);
    }
  }

  @keyframes threeToTwo {
    from {
      transform: scale(1.3) translate(0, -40px);
    }
    to {
      transform: rotate(-15deg) translate(-480px, -50px);
    }
  }

  @keyframes threeToTwoSmall {
    from {
      transform: scale(1.3) translate(0, -40px);
    }
    to {
      transform: rotate(-15deg) translate(-335px, -80px);
    }
  }

  @keyframes fourToThree {
    from {
      transform: rotate(15deg) translate(480px, -50px);
    }
    to {
      transform: scale(1.3) translate(0, -40px);
    }
  }

  @keyframes fourToThreeSmall {
    from {
      transform: rotate(15deg) translate(335px, -80px);
    }
    to {
      transform: scale(1.3) translate(0, -40px);
    }
  }

  @keyframes fiveToFour {
    from {
      transform: rotate(30deg) translate(905px, -230px);
    }
    to {
      transform: rotate(15deg) translate(480px, -50px);
    }
  }

  @keyframes fiveToFourSmall {
    from {
      transform: rotate(30deg) translate(905px, -230px);
    }
    to {
      transform: rotate(15deg) translate(335px, -80px);
    }
  }
  @keyframes sixToFive {
    from {
      transform: rotate(30deg) translate(1400px, -100px);
    }
    to {
      transform: rotate(30deg) translate(905px, -230px);
    }
  }
`;

export const ArrowContainer = styled.section`
  ${container}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -15rem;
  gap: ${spacer16};

  ${maxWidth.sm`
    margin-top: -28rem;
  `}
`;

export const StatCardContainer = styled(StatCard)`
  width: 33rem;
  height: 41rem;
  padding: 1rem;
  ${maxWidth.sm`
      height: 35rem;
      width: 26rem;
      padding: 0.5rem;
  `}
`;
