import styled from 'styled-components';
import { Eyebrow, Paragraph } from 'components/atoms';
import { container } from 'styles/grid';
import { lowerNavHeight, navHeight } from 'styles/variables';
import { minWidth } from 'styles/media-queries';
import { sectionMargins, spacer12, spacer24, spacer32 } from 'styles/spacers';
import { black, white, white20OpacityOnBlack } from 'styles/colors';
import {
  ACTIVATE_ANIMATION_CLASS,
  SETUP_ANIMATION_CLASS,
  fadeContentSlideUp,
} from 'styles/animations';

export const Header = styled.header`
  background-color: ${({ theme }) => theme.backgroundColor};
  ${({ theme }) =>
    theme.hasLowerNav
      ? `padding-top: calc(${navHeight.smToLg}rem + ${lowerNavHeight.sm}rem);`
      : `padding-top: ${navHeight.smToLg}rem;`}

  ${minWidth.lg`
    ${({ theme }) =>
      theme.hasLowerNav
        ? `padding-top: calc(${navHeight.mlUp}rem + ${lowerNavHeight.mlUp}rem);`
        : `padding-top: ${navHeight.mlUp}rem`}
  `}

  .${SETUP_ANIMATION_CLASS} {
    ${fadeContentSlideUp.setup}
  }

  .${ACTIVATE_ANIMATION_CLASS} {
    ${fadeContentSlideUp.play}
  }
`;

export const FullBleedContainer = styled.div`
  width: 100%;
  height: calc(100vw * 9 / 16);
`;

export const Container = styled.div`
  ${sectionMargins}
  ${container}
  display: flex;
  flex-direction: column;
  gap: ${spacer32};

  ${minWidth.lg`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  `}
`;

export const TextContainer = styled.div`
  color: ${({ theme }) => theme.titleColor || white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${spacer24};
  ${({ theme }) =>
    theme.textAlign === 'left' &&
    `
    ${
      theme.hasMedia && theme.mediaAlign !== 'full'
        ? `grid-column: 1 / span 6;`
        : `
        grid-column: span 12;`
    }
  `}
  ${({ theme }) =>
    theme.textAlign === 'right' &&
    `
    grid-column: ${theme.hasMedia ? `7 / span 6` : `span 12`};
  `}
  ${({ theme }) =>
    theme.textAlign === 'center' &&
    `
    grid-column: 4 / span 6;
    align-items: center;
  `}
  text-align: ${({ theme }) =>
    theme.textAlign === 'center' ? 'center' : 'left'};

  .${ACTIVATE_ANIMATION_CLASS} {
    &:nth-child(1) {
      animation-delay: 400ms;
    }
    &:nth-child(2) {
      animation-delay: 600ms;
    }
    &:nth-child(3) {
      animation-delay: 800ms;
    }
    &:nth-child(4) {
      animation-delay: 1000ms;
    }
    &:nth-child(5) {
      animation-delay: 1200ms;
    }
    &:nth-child(6) {
      animation-delay: 1400ms;
    }
  }
`;

export const EyebrowText = styled(Eyebrow)`
  margin-bottom: -${spacer24};
`;

export const Description = styled(Paragraph)`
  ${minWidth.lg`
    ${({ theme }) =>
      theme.textAlign === 'left' &&
      !theme.hasMedia &&
      `width: 60%;
    `}
  `}
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: start;
  gap: ${spacer12};

  .tag-button {
    color: ${({ theme }) => theme.titleColor || white};

    &:active {
      color: ${({ theme }) => (theme.titleColor === white ? black : white)};
      background-color: ${({ theme }) =>
        theme.titleColor === white ? white : white20OpacityOnBlack};
    }
    &:hover {
      color: ${({ theme }) => (theme.titleColor === white ? black : white)};
      background-color: ${({ theme }) =>
        theme.titleColor === white ? white : white20OpacityOnBlack};
    }
  }
`;

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacer12};
`;

export const BylineContainer = styled.div`
  grid-column: span 12;
`;

export const MediaContainer = styled.div`
  ${({ theme }) =>
    theme.hasText &&
    `
    grid-column: ${theme.mediaAlign === 'left' ? `1 / span 6` : `8 / span 5`};
  `};

  .media {
    border-radius: 8px;
  }

  &.${ACTIVATE_ANIMATION_CLASS} {
    animation-delay: 800ms;
  }
`;

export const Image = styled.div`
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center top; /* First value is from left and second is from top. You can use use number as well*/
  background-repeat: no-repeat;
  width: 100%;
  max-height: 100%;
  aspect-ratio: 1/1;
`;

export const HeroVideoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  video {
    width: 100%;
    object-fit: cover;
  }
`;
