import styled from 'styled-components';

import { Cta as CtaComponent, Icon } from 'components/atoms';
import { ratioPercentage, cssFragments, fontWeights } from 'styles/variables';
import { columnsGutter, container } from 'styles/grid';
import { Markdown as MarkdownComponent } from 'components/molecules/markdown';
import { maxWidth, minWidth } from 'styles/media-queries';
import { white, darkGray, white20OpacityOnBlack, black } from 'styles/colors';
import { spacer4, sectionMargins, articleMargins } from 'styles/spacers';
import { INLINE_CARD_TILE_THEMES } from 'utils/get-theme-selector';

// Styled components
export const Container = styled.section`
  ${container}
  ${props => (props.theme.isArticleMargin ? articleMargins : sectionMargins)}
  ${minWidth.lg`
    ${props =>
      props.theme.isArticleMargin &&
      `
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    `}
  `}
`;

export const InlineCard = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor || black};
  color: ${({ theme }) => theme.textColor || white};
  border-radius: 8px;

  ${minWidth.lg`
    ${columnsGutter}
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    ${props =>
      props.theme.isArticleMargin &&
      `
      grid-column: 3 / 11;
    `}
  `}

  ${props =>
    props.theme.fullWidthImage &&
    `
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  `}

  ${maxWidth.sm`
    ${props =>
      props.theme.fullWidthImage &&
      `
      display: flex;
      flex-direction: column-reverse;
    `}
  `}
`;

export const Image = styled.div.attrs(props => ({
  role: props.alt ? 'img' : 'presentation',
  'aria-label': props.alt,
}))`
  display: block;
  border-radius: 8px;
  overflow: hidden;
  padding-top: ${ratioPercentage.oneOne}%;
  position: relative;

  &::before {
    ${cssFragments.backgroundScaledImageAdFormats}

    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 4rem;
    top: 0;
  }

  ${maxWidth.sm`
     &::before {
      right: 2rem;
    }
  `}

  ${minWidth.lg`
    grid-column: 1 / span 4;

    ${props =>
      props.theme.fullWidthImage &&
      `
        grid-column: 1 / span 6;
      `}
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ theme }) =>
    theme.themeSelectorType === INLINE_CARD_TILE_THEMES.HEADLINE &&
    `
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
  `}

  ${props =>
    (props.theme.hasImage || props.theme.fullWidthImage) &&
    `
    padding: 2.4rem;
    align-items: start;
  `}

  ${minWidth.lg`
    grid-column: 1 / span 12;

    ${props =>
      (props.theme.hasImage || props.theme.fullWidthImage) &&
      `
      grid-column: 7 / span 6;
    `}

    ${({ theme }) =>
      theme.themeSelectorType === INLINE_CARD_TILE_THEMES.TEXT_CENTER &&
      `
      grid-column: 3 / span 8;
    `}

    ${({ theme }) =>
      theme.themeSelectorType === INLINE_CARD_TILE_THEMES.TEXT_SPLIT &&
      `
      display: grid;
      grid-template-columns: repeat(12,1fr);
      align-items: start;
    `}
  `}
`;

export const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.textColor || white};

  span {
    margin-top: 1rem;
    letter-spacing: -0.05rem;
  }

  ${props =>
    props.theme.fullWidthImage &&
    `
    text-align: left;
    align-items: normal;
    width: 100%;
  `}

  ${maxWidth.sm`
    width: 100%;
    text-align: left;
    margin-left: unset;
    align-items: start;

    ${({ theme }) =>
      theme.themeSelectorType === INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR &&
      `
      text-align: center;
      align-items: center;
    `}

     ${({ theme }) =>
       theme.themeSelectorType === INLINE_CARD_TILE_THEMES.TEXT_SPLIT &&
       `
        padding-right: 2rem;
        margin-bottom: 1.25rem;

       h1 {
        letter-spacing: -0.2rem;
        font-size: 4.5rem;
        line-height: 5rem;
       }
    `}
  `}

  ${minWidth.lg`
     ${props =>
       !props.hasEyebrow &&
       `
          margin-top: -2rem;
       `}

     ${({ theme }) =>
       theme.themeSelectorType === INLINE_CARD_TILE_THEMES.TEXT_SPLIT &&
       `
       grid-column: 1 / span 6;
       align-items: start;
       text-align: left;
    `}
  `}
`;

export const DescriptionContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 85%;

  ${props =>
    props.theme.fullWidthImage &&
    `
    align-items: start;
    width: 100%;
    margin: 0;
  `}

  ${({ theme }) =>
    theme.themeSelectorType === INLINE_CARD_TILE_THEMES.HEADLINE &&
    `
    grid-column: 8 / span 6;
    align-items: center;
    width: unset;
    margin: 0;
  `}

  ${({ theme }) =>
    theme.themeSelectorType === INLINE_CARD_TILE_THEMES.TEXT_SPLIT &&
    `
    margin-top: unset;
    align-items: start;
    text-align: left;
  `}

  ${maxWidth.sm`
    width: 100%;
    align-items: start;
    text-align: left;

    ${({ theme }) =>
      theme.themeSelectorType === INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR &&
      `
      text-align: center;
    `}

    ${({ theme }) =>
      theme.themeSelectorType === INLINE_CARD_TILE_THEMES.HEADLINE &&
      `
      align-items: end;
    `}
  `}

  ${minWidth.lg`

    ${({ theme }) =>
      theme.themeSelectorType === INLINE_CARD_TILE_THEMES.TEXT_SPLIT &&
      `
      grid-column: 8 / span 6;
      align-items: start;
      justify-content: start;
      width: 100%;
    `}

     ${({ theme }) =>
       theme.themeSelectorType === INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR &&
       `
      padding-bottom: 8rem;
      margin-top: unset;
      width: 50%;
    `}
  `}
`;

export const CtasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.4rem;
  gap: 24px;

  ${({ theme }) =>
    theme.themeSelectorType === INLINE_CARD_TILE_THEMES.HEADLINE &&
    `
    padding: 0;
  `}

  ${({ theme }) =>
    theme.themeSelectorType === INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR &&
    `
      display: grid;
      grid-template-columns: 2fr;
      width: 100%;
  `}

  ${minWidth.md`
     ${({ theme }) =>
       theme.themeSelectorType === INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR &&
       `
        grid-template-columns: repeat(2, 1fr);
    `}
  `}

  ${minWidth.lg`
     ${({ theme }) =>
       theme.themeSelectorType === INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR &&
       `
        grid-template-columns: repeat(3, 1fr);
    `}
  `}
`;

export const Markdown = styled(MarkdownComponent)`
  span,
  p {
    ${minWidth.lg`
      font-weight: ${fontWeights.normal_p}
    `}
  }
`;

export const Cta = styled(CtaComponent)`
  max-width: 70rem;

  ${({ theme }) =>
    theme.themeSelectorType === INLINE_CARD_TILE_THEMES.INDUSTRY_SELECTOR &&
    `
    background-color: ${darkGray};
    border-style: none;
    color: ${white};
    height: 7rem;
    width: 100%;
    font-size: 2.1rem;
    letting-spacing: -0.1rem;
    font-weight: bolder;
    padding-left: ${spacer4};

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: ${white20OpacityOnBlack};
    }
  `}
`;

export const Arrow = styled(Icon)`
  width: 3rem;
  height: 3rem;
  color: ${white};
  display: flex;
  align-items: center;

  svg {
    width: 2rem;
    height: 2rem;
  }

  path {
    fill: ${white};
  }
`;
