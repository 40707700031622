import React, { useState } from 'react';
import PropTypes, { any } from 'prop-types';
import { ThemeProvider } from 'styled-components';

import FocusTrap from 'focus-trap-react';
import mParticle from '@mparticle/web-sdk';
import { useRouter } from 'next/router';

import { ICONS } from 'constants/icons';
import { KEYCODES } from 'constants/keycodes';
import { getLinkProps } from 'utils/get-link-props';
import { MODULES_MULTI_CTA } from 'utils/google-tag-manager';
import * as Styled from './Navigation.styled';

/**
 * List with the type of components for SubNav
 */
const COMPONENTS = {
  DROPDOWN: 'Dropdown',
  DROPDOWN_MOBILE: 'DropdownMobile',
  MAIN: 'Main',
  MAIN_MOBILE: 'MainMobile',
  LOWER_NAV_LINK: 'LowerNavLink',
};

const onDropdownMenuClick = () => {
  mParticle.logEvent('action', mParticle.EventType.Other, {
    action_text: 'menu click',
    action_location: MODULES_MULTI_CTA.dropdown,
  });
};

const onDropdownItemClick = () => {
  mParticle.logEvent('action', mParticle.EventType.Other, {
    action_text: 'sub navigation link click',
    action_location: MODULES_MULTI_CTA.dropdown,
  });
};

/**
 * Creates the Dropdown Menu for Desktop
 * @param {object} item Navigation Item data
 * @param className
 */
const Dropdown = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ThemeProvider
      theme={{
        subNavFocused: isOpen,
      }}
    >
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{
          onDeactivate: () => {
            setIsOpen(false);
            onDropdownMenuClick();
          },
          clickOutsideDeactivates: true,
        }}
      >
        <div tabIndex={-1}>
          <Styled.DropdownLink
            tabIndex={0}
            onClick={() => {
              setIsOpen(!isOpen);
              onDropdownMenuClick();
            }}
          >
            {item.rootLink.title}
            <Styled.Caret name={ICONS.CARET_DOWN_SMALL} />
          </Styled.DropdownLink>
          <Styled.DropdownContainer tabIndex={0}>
            {item.subLinksCollection.items.map(({ sys, title, url }, index) => {
              const { href: subHref, asLink: subAsLink } = getLinkProps(url);
              return (
                <Styled.DropdownItem
                  key={sys.id}
                  href={subAsLink || subHref}
                  onClick={() => {
                    setIsOpen(false);
                    onDropdownItemClick();
                  }}
                  tabIndex={index + 1}
                  $index={index}
                >
                  {title}
                </Styled.DropdownItem>
              );
            })}
          </Styled.DropdownContainer>
        </div>
      </FocusTrap>
    </ThemeProvider>
  );
};

/**
 * Creates the Dropdown Menu Page for mobile
 * @param {object} item Navigation Item data
 * @param {number} index Index of the item
 * @param {Function} onClick On Click handler for links
 * @param className
 */
const DropdownMobile = ({ item, index, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onKeyDown = e => {
    if (e.keyCode === KEYCODES.ESCAPE || e.keyCode === KEYCODES.ENTER) {
      setIsOpen(false);
    }
  };

  return (
    <ThemeProvider
      theme={{
        subNavFocused: isOpen,
      }}
    >
      <Styled.MobileDropdownLink
        $index={index}
        onClick={() => {
          setIsOpen(!isOpen);
          onDropdownMenuClick();
        }}
      >
        {item.rootLink.title}
        <Styled.MobileCaret name={ICONS.CARET_DOWN_SMALL} />
      </Styled.MobileDropdownLink>
      <Styled.DropdownMobileContainer onKeyDown={onKeyDown}>
        <Styled.MobileDropdownLink
          onClick={() => {
            setIsOpen(!isOpen);
            onDropdownMenuClick();
          }}
        >
          <Styled.MobileCaret name={ICONS.CARET_DOWN_SMALL} />
        </Styled.MobileDropdownLink>
        {item.subLinksCollection.items.map(({ sys, title, url }) => {
          const { href: subHref, asLink: subAsLink } = getLinkProps(url);
          return (
            <Styled.DropdownMobileItem
              key={sys.id}
              href={subAsLink || subHref}
              onClick={() => {
                onClick();
                onDropdownItemClick();
              }}
            >
              {title}
            </Styled.DropdownMobileItem>
          );
        })}
      </Styled.DropdownMobileContainer>
    </ThemeProvider>
  );
};

const onMenuClick = () => {
  mParticle.logEvent('action', mParticle.EventType.Other, {
    action_text: 'navigation link click',
    action_location: MODULES_MULTI_CTA.navigationBar,
  });
};

/**
 * Creates a regular navigation item for desktop
 * @param {object} item Navigation Item data
 * @returns {ReactElement}
 */
const MainNavLink = ({ item }) => {
  const router = useRouter();
  const { locale, asPath } = router;
  const isActive = asPath.includes(item.slug);
  return (
    <Styled.TextNavLink
      href={`/${locale}/${item.slug}`}
      onClick={onMenuClick}
      $isActive={isActive}
    >
      {item.title}
    </Styled.TextNavLink>
  );
};

/**
 * Creates a regular navigation item for mobile
 * @param {object} item Navigation Item data
 * @param {number} index Index of the item
 * @param {Function} onClick On Click handler for links
 * @returns {ReactElement}
 */
const MainMobileNavLink = ({ item, index, onClick }) => {
  const { locale } = useRouter();

  return (
    <Styled.MobileTextNavLink
      $index={index}
      href={`/${locale}/${item.slug}`}
      onClick={() => {
        onClick();
        onMenuClick();
      }}
    >
      {item.title}
    </Styled.MobileTextNavLink>
  );
};

/**
 * Creates a regular navigation item for mobile
 * @param {object} item Navigation Item data
 * @param {string} slug Slug for lower navigation links
 * @returns {ReactElement}
 */
const LowerNavLink = ({ item, slug }) => {
  const router = useRouter();
  const { locale, query } = router;
  const isLandingLink = typeof slug === 'string' && slug.startsWith('landing');
  const linkHref =
    !slug || isLandingLink
      ? `/${locale}/${item.slug}`
      : `/${locale}/${slug}/${item.slug}`;
  const isActive = isLandingLink
    ? query.tag === item.slug
    : query.slug === item.slug;

  return (
    <Styled.LowerNavLink
      href={linkHref}
      onClick={onMenuClick}
      $isActive={isActive}
    >
      {item.title}
    </Styled.LowerNavLink>
  );
};

/**
 * Creates a dropdown or a Navigation Link depending on the data.
 * @param {object} item Navigation Item data
 * @param {string} type Type of component
 * @param {number} index Index of the item
 * @param {string} slug Slug for lower navigation links
 * @param {Function} onClick On Click handler for links
 * @returns {ReactElement}
 */
export const NavElement = ({ item, type, index, onClick, slug }) => {
  switch (type) {
    case COMPONENTS.DROPDOWN:
      return <Dropdown item={item} />;
    case COMPONENTS.DROPDOWN_MOBILE:
      return <DropdownMobile item={item} index={index} onClick={onClick} />;
    case COMPONENTS.MAIN_MOBILE:
      return <MainMobileNavLink item={item} index={index} onClick={onClick} />;
    case COMPONENTS.LOWER_NAV_LINK:
      return <LowerNavLink item={item} slug={slug} />;
    default:
      return <MainNavLink item={item} />;
  }
};

NavElement.propTypes = {
  /**
   * Navigation Item data
   */
  item: PropTypes.objectOf(any).isRequired,
};
