import React, { useState, useEffect, useRef } from 'react';
import PropTypes, { any } from 'prop-types';

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { debounce } from 'lodash';
import kebabCase from 'lodash/kebabCase';
import mParticle from '@mparticle/web-sdk';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';

import { Cta, Icon } from 'components/atoms';
import { ICONS } from 'constants/icons';
import { spotifyGreen, gray, black, white } from 'styles/colors';
import { isXSmallToMedium } from 'styles/media-queries';
import { getLinkProps } from 'utils/get-link-props';
import { MODULES_MULTI_CTA } from 'utils/google-tag-manager';
import getMultiCtaAction from 'utils/google-tag-manager/getMultiCtaAction';

import { NavElement } from './NavElement';
import * as Styled from './Navigation.styled';

const RESIZE_DEBOUNCE_DELAY = 100;

/**
 * Global Navigation Component, creates the top global navigation of the page.
 * @param {Array} props.navItems navigationItemsCollection navigation items
 * @param {Array} props.ctaItems ctasCollection CTA items
 * @param {string} props.backgroundColor the background color of the navigation
 * @param {string} props.textColor the text color of the navigation
 * @param {boolean} props.hideNavigationLinks hide navigation links
 * @return {ReactComponent}
 */
const Navigation = ({
  navItems = [],
  ctaItems = [],
  backgroundColor,
  textColor,
  hideNavigationLinks = false,
}) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const mobileNavOverlayElement = useRef(null);

  // mobile detection
  useEffect(() => {
    setIsMobile(isXSmallToMedium());

    const onResize = debounce(() => {
      setIsMobile(isXSmallToMedium());
      setIsMobileNavOpen(false);
    }, RESIZE_DEBOUNCE_DELAY);
    window.addEventListener('resize', onResize);

    return () => {
      onResize.cancel();
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // for hiding and showing navigation on scroll
  useEffect(() => {
    let prevScrollPosition = window.scrollY;
    window.addEventListener('scroll', () => {
      const currentScrollPositon = window.scrollY;
      setShowNav(
        prevScrollPosition > currentScrollPositon || currentScrollPositon < 5,
      );

      if (currentScrollPositon >= 5) {
        prevScrollPosition = currentScrollPositon;
      }
    });

    return () => {
      window.removeEventListener('scroll');
    };
  }, []);

  // Disable, enable scroll on mobile overlay open / close
  useEffect(() => {
    if (isMobile && isMobileNavOpen) {
      disableBodyScroll(mobileNavOverlayElement.current);
    } else {
      enableBodyScroll(mobileNavOverlayElement.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isMobileNavOpen]);

  // Navigation Items
  const mainNavItems = navItems.slice(0, -1);
  const dropdownItem = navItems.at(-1);

  // CTA button
  const createAdButton = ctaItems.map(
    ({ title, url, type, overrideFunctionality }) => {
      const { href, asLink } = getLinkProps(url);
      const ctaClickTrack = () => {
        const actionText = getMultiCtaAction({ overrideFunctionality, url });
        mParticle.logEvent('action', mParticle.EventType.Other, {
          action_text: actionText,
          action_location: MODULES_MULTI_CTA.navigationBar,
        });
      };
      return (
        <div key={kebabCase(`${title}-${url}`)} className="create-button">
          <Cta
            type={type}
            overrideFunctionality={overrideFunctionality}
            onClick={ctaClickTrack}
            href={href}
            asLink={asLink}
            mobileOverrideText={isMobile ? t('signUp') : t('createAnAd')}
          >
            {title}
          </Cta>
        </div>
      );
    },
  );

  // colors
  const highlightColor =
    backgroundColor && backgroundColor !== black && backgroundColor !== white
      ? gray
      : spotifyGreen;

  return (
    <ThemeProvider
      theme={{
        backgroundColor,
        highlightColor,
        showNav,
        logoColor:
          textColor === white || backgroundColor === black
            ? highlightColor
            : black,
        textColor: textColor || white,
        isMobileNavOpen,
      }}
    >
      <Styled.Header>
        <Styled.Container>
          <Styled.Logo
            href={`/${locale}/`}
            onClick={() => {
              mParticle.logEvent('action', mParticle.EventType.Other, {
                action_text: 'logo sclick',
                action_location: MODULES_MULTI_CTA.navigationBar,
              });
            }}
          >
            <span className="sr-only">{t('spotifyAdvertising')}</span>
            <Icon name={ICONS.SPOTIFY_ADVERTISING} />
          </Styled.Logo>
          {isMobile ? (
            <Styled.MobileNavContainer>
              {createAdButton}
              <Styled.MobileNavIconButton
                aria-label={
                  isMobileNavOpen
                    ? t('navigationOverlay.close')
                    : t('navigationOverlay.open')
                }
                aria-expanded={isMobileNavOpen}
                onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
              >
                <Styled.MobileIcon
                  className={isMobileNavOpen ? 'show' : 'hide'}
                  name={ICONS.CLOSE_MARK}
                />
                <Styled.MobileIcon
                  className={isMobileNavOpen ? 'hide' : 'show'}
                  name={ICONS.HAMBURGER}
                />
              </Styled.MobileNavIconButton>
            </Styled.MobileNavContainer>
          ) : (
            <Styled.NavContainer>
              {!hideNavigationLinks && (
                <Styled.NavItemsContainer>
                  <Styled.MainNavItemsContainer>
                    {mainNavItems.map(item => (
                      <NavElement key={item.sys.id} item={item} type="Main" />
                    ))}
                  </Styled.MainNavItemsContainer>
                  <NavElement item={dropdownItem} type="Dropdown" />
                </Styled.NavItemsContainer>
              )}
              {createAdButton}
            </Styled.NavContainer>
          )}
        </Styled.Container>
      </Styled.Header>
      {isMobile && (
        <Styled.MobileBackdrop>
          <Styled.MobileBackdropContainer>
            <Styled.MobileMainNavContainer>
              {mainNavItems.map((item, index) => (
                <NavElement
                  index={index}
                  key={item.sys.id}
                  item={item}
                  type="MainMobile"
                  onClick={() => setIsMobileNavOpen(false)}
                />
              ))}
              {dropdownItem && (
                <NavElement
                  index={navItems.length - 1}
                  key={dropdownItem.sys.id}
                  item={dropdownItem}
                  type="DropdownMobile"
                  onClick={() => setIsMobileNavOpen(false)}
                />
              )}
              <Styled.MobileCtaContainer>
                {createAdButton}
              </Styled.MobileCtaContainer>
            </Styled.MobileMainNavContainer>
          </Styled.MobileBackdropContainer>
        </Styled.MobileBackdrop>
      )}
    </ThemeProvider>
  );
};

Navigation.propTypes = {
  /**
   * Navigation Items
   */
  navItems: PropTypes.arrayOf(any),
  /**
   * CTA Items
   */
  ctaItems: PropTypes.arrayOf(any),
  /**
   * Background color of the navigation
   */
  backgroundColor: PropTypes.string,
  /**
   * Hide navigation links
   */
  hideNavigationLinks: PropTypes.bool,
};

export default Navigation;
