import React, { useState, useEffect } from 'react';
import PropTypes, { any } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { debounce } from 'lodash';

import { ICONS } from 'constants/icons';
import { spotifyGreen, gray, black, white } from 'styles/colors';
import { isXSmallToMedium } from 'styles/media-queries';

import { NavElement } from './NavElement';
import * as Styled from './LowerNavigation.styled';

const RESIZE_DEBOUNCE_DELAY = 250;

/**
 * Global Navigation Component, creates the top global navigation of the page.
 * @param {Array} props.navItems navigationItemsCollection navigation items
 * @param {string} props.backgroundColor the background color of the navigation
 * @param {string} props.textColor the text color of the navigation
 * @param {string} props.lowerNavigationSlug the slug of the lower navigation
 * @param {boolean} props.isHomepage navigation items are for the homepage
 * @return {ReactComponent}
 */
const LowerNavigation = ({
  navItems = [],
  backgroundColor,
  textColor,
  lowerNavigationSlug = null,
  isHomepage = false,
}) => {
  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // for hiding and showing navigation on scroll
  useEffect(() => {
    let prevScrollPosition = window.scrollY;
    window.addEventListener('scroll', () => {
      const currentScrollPositon = window.scrollY;
      setShowNav(
        prevScrollPosition > currentScrollPositon || currentScrollPositon < 5,
      );

      if (currentScrollPositon >= 5) {
        prevScrollPosition = currentScrollPositon;
      }
    });

    return () => {
      window.removeEventListener('scroll');
    };
  }, []);

  // extra check on mobile using window for component side rendering
  useEffect(() => {
    setIsMobile(isXSmallToMedium());

    const onResize = debounce(() => {
      setIsMobile(isXSmallToMedium());
    }, RESIZE_DEBOUNCE_DELAY);
    window.addEventListener('resize', onResize);

    return () => {
      onResize.cancel();
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const highlightColor =
    backgroundColor && backgroundColor !== black && backgroundColor !== white
      ? gray
      : spotifyGreen;

  if (isHomepage && !isMobile) {
    return null;
  }

  return (
    <ThemeProvider
      theme={{
        backgroundColor,
        highlightColor,
        textColor: textColor || white,
        showNav,
      }}
    >
      <Styled.Header>
        <Styled.Container>
          <Styled.BarContainer>
            <Styled.ExploreContainer>
              <p>Explore</p>
              <Styled.Arrow name={ICONS.ARROW_RIGHT_SMALL} />
            </Styled.ExploreContainer>
            <Styled.LowerNavContainer>
              {navItems.map(item => (
                <NavElement
                  key={item.sys.id}
                  item={item}
                  type="LowerNavLink"
                  slug={lowerNavigationSlug}
                />
              ))}
            </Styled.LowerNavContainer>
          </Styled.BarContainer>
        </Styled.Container>
      </Styled.Header>
    </ThemeProvider>
  );
};

LowerNavigation.propTypes = {
  /**
   * Navigation Items
   */
  navItems: PropTypes.arrayOf(any),
  /**
   * Background color of the navigation
   */
  backgroundColor: PropTypes.string,
  /**
   * Text color of the navigation
   */
  textColor: PropTypes.string,
  /**
   * Slug of the lower navigation
   */
  lowerNavigationSlug: PropTypes.string,
  /**
   * Navigation items are for the homepage
   */
  isHomepage: PropTypes.bool,
};

export default LowerNavigation;
