import React from 'react';

import get from 'lodash/get';

import Custom404 from 'pages/404';
import ErrorPage from 'pages/_error';
import { CODE_500 } from 'constants/errors';
import {
  Footer,
  FooterForm,
  Navigation,
  LowerNavigation,
} from 'components/organisms';

export default function PageDataLoader({
  WrappedComponent,
  showLowerNavHome = false,
  componentId,
  navigationData,
  navCollectionName,
  footerData,
  footerCollectionName,
  pageData,
  pageError,
  pageLoading,
}) {
  if (pageLoading) return <section>Loading... (Spinner component?)</section>;

  if (pageError) {
    // Handling nextjs pages/_error.js manually in case of Graphql errors
    const initialProps = ErrorPage.getInitialProps({
      err: { statusCode: CODE_500 },
    });

    return <ErrorPage {...initialProps} />;
  }

  if (!pageData) return <Custom404 />;

  const hideNavigationLinks =
    get(pageData, 'hideNavigationLinks', false) || false;
  const lowerNavigationData = get(
    pageData,
    'lowerNavigation.navigationItemsCollection.items',
    [] || '',
  );
  const lowerNavigationSlug =
    get(pageData, 'lowerNavigation.slug', null) || null;
  const hideFooterLinks = get(pageData, 'hideFooterLinks', false) || false;
  const hideContactForm = get(pageData, 'hideContactForm', false) || false;
  const [navigationItem] = get(
    navigationData,
    `${navCollectionName}.items`,
    [],
  );
  const mainNavItems = get(
    navigationItem,
    'navigationItemsCollection.items',
    [],
  );
  const homepageNavData = mainNavItems.filter(item => item.type !== 'Dropdown');

  return (
    <>
      {navigationData && (
        <Navigation
          navItems={mainNavItems}
          ctaItems={get(navigationItem, 'ctasCollection.items', [])}
          backgroundColor={pageData?.hero?.backgroundColor}
          textColor={pageData?.hero?.titleColor}
          hideNavigationLinks={hideNavigationLinks}
        />
      )}
      {lowerNavigationData &&
        lowerNavigationData.length > 0 &&
        !hideNavigationLinks && (
          <LowerNavigation
            navItems={lowerNavigationData}
            lowerNavigationSlug={lowerNavigationSlug}
            backgroundColor={pageData?.hero?.backgroundColor}
            textColor={pageData?.hero?.titleColor}
          />
        )}
      {showLowerNavHome &&
        homepageNavData &&
        homepageNavData.length > 0 &&
        !hideNavigationLinks && (
          <LowerNavigation
            navItems={homepageNavData}
            backgroundColor={pageData?.hero?.backgroundColor}
            textColor={pageData?.hero?.titleColor}
            isHomepage
          />
        )}
      <main id="main-content" role="main">
        <WrappedComponent key={componentId} pageData={pageData} />
      </main>
      {!hideContactForm && <FooterForm />}
      {footerData && (
        <Footer
          hideFooterLinks={hideFooterLinks}
          data={footerData}
          collectionName={footerCollectionName}
        />
      )}
    </>
  );
}
