import { gql } from '@apollo/client';

import { getLocalizedField } from 'utils/get-localized-field';
import { DYNAMIC_FRAGMENT_LITERALS } from 'queries/fragment-literals';
import {
  COMPONENT_FRAGMENTS,
  MODULE_FRAGMENTS,
  DYNAMIC_FRAGMENTS,
} from 'queries/fragments';

// Note that while home pages do not have field-level localization, we must pass $locale to gql for curated list to work correctly.
// A curated list can contain a global detail page, which requires $locale.
const PAGE_HOME_QUERY = {
  body: ({ collection = 'pageHomeCollection' }) => gql`
    query HomePage($preview: Boolean = false, $homeSlug: String!, $locale: String!) {
      ${collection}(
        preview: $preview
        where: { slug: $homeSlug }
        locale: $locale
        limit: 1
      ) {
        items {
          meta {
            ...MetaFields
          }
          hero {
            ...HeroFields
          }
          heroDynamic {
            ...HeroDynamic
          }
          subhead
          introText
          backgroundColor
        }
      }
    }
    ${COMPONENT_FRAGMENTS.CTA}
    ${COMPONENT_FRAGMENTS.IMAGE}
    ${COMPONENT_FRAGMENTS.META}
    ${COMPONENT_FRAGMENTS.HERO}
    ${COMPONENT_FRAGMENTS.HERO_DYNAMIC}
  `,
  modules: ({
    collection = 'pageHomeCollection',
    collectionLocale = '',
  }) => gql`
    query HomePage($preview: Boolean = false, $homeSlug: String!, $locale: String!) {
      ${collection}(
        preview: $preview
        where: { slug: $homeSlug }
        locale: $locale
        limit: 1
      ) {
        items {
          modulesCollection {
            items {
              ... on ModuleStatement {
                ${DYNAMIC_FRAGMENT_LITERALS.STATEMENT(
                  getLocalizedField(collectionLocale),
                )}
              }
              ... on ModuleFeatureCard {
                ...FeatureCard
              }
              ... on ModuleInlineCard {
                ...InlineCard
              }
              ... on ModuleSliderTab {
                ...SliderTab
              }
              ... on ModuleStatSlideshow {
                ...StatSlideshow
              }
              ... on ModuleTrendGrid {
                ...TrendGrid
              }
              ... on ModuleTileCarousel {
                ...TileCarouselContentTile
              }
              ... on ModuleSectionDivider {
                ...SectionDivider
              }
              ... on ModuleFootnotes {
                ...Footnotes
              }
            }
          }
        }
      }
    }
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_DETAIL(
      getLocalizedField(collectionLocale),
    )}
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_LANDING(
      getLocalizedField(collectionLocale),
    )}
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_GLOBAL}
    ${COMPONENT_FRAGMENTS.IMAGE}
    ${COMPONENT_FRAGMENTS.CTA}
    ${COMPONENT_FRAGMENTS.STAT_CARD}
    ${COMPONENT_FRAGMENTS.CTA_CARD}
    ${MODULE_FRAGMENTS.FEATURE_CARD}
    ${MODULE_FRAGMENTS.INLINE_CARD}
    ${MODULE_FRAGMENTS.STAT_SLIDESHOW}
    ${MODULE_FRAGMENTS.TREND_GRID}
    ${COMPONENT_FRAGMENTS.CONTENT_TILE_NO_TRENDS}
    ${MODULE_FRAGMENTS.GALLERY}
    ${MODULE_FRAGMENTS.SLIDER_TAB}
    ${MODULE_FRAGMENTS.TILE_CAROUSEL_ONLY_CONTENT_TILE}
    ${MODULE_FRAGMENTS.SECTION_DIVIDER}
    ${MODULE_FRAGMENTS.FOOTNOTES}
  `,
};

export default PAGE_HOME_QUERY;
