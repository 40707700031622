import React, { useCallback } from 'react';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import { useAppContext, ACTION_TYPES } from 'contexts/app-context';
import { COMMON_LINK_PROPS } from 'constants/common-props';
import { CTA_OVERRIDES } from 'constants/cta-overrides';
import { ActivityIndicator } from 'components/atoms/activity-indicator';
import { getRandomHash } from 'utils/get-random-hash';
import { getURL } from 'utils/get-url';
import { AUTH_PAGE, REDIRECT_STATE, LANG_MAP } from 'constants/auth';
import { BROWSE_ADS, SPOTIFY_AUTHORIZE } from 'constants/urls';

import { Icon } from 'components/atoms';
import mParticle from '@mparticle/web-sdk';
import { CTA_TYPES } from 'constants/cta-types';
import * as Styled from './Cta.styled';

const { publicRuntimeConfig } = getConfig() || {};

function getStyledCtaByType(themeType) {
  switch (themeType) {
    case CTA_TYPES.PRIMARY:
      return Styled.PrimaryCta;
    case CTA_TYPES.SECONDARY:
      return Styled.SecondaryCta;
    case CTA_TYPES.WRAPPER:
      return Styled.Wrapper;
    case CTA_TYPES.TEXT_LINK:
      return Styled.TextLink;
    case CTA_TYPES.ARTICLE_TAG:
      return Styled.ArticleTag;
    default:
      return Styled.PrimaryCta;
  }
}

/**
 * CTA component
 * @param {String} href Url of the link or dynamic definition in case of dynamic link
 * @param {String} asLink Next Link as prop
 * @param {String} tag HTML tag override in case of a non anchor cta
 * @param {String} type CTA type: primary or secondary
 * @param {String} overrideFunctionality override functionality for AdStudio Login and Lead Gen Form
 * @param {String} mobileOverrideText mobile text for AdStudio Login
 * @param {Function} onClick - on click handler function
 * @param {String|Array} children React Children
 * @param {String} className Class to override current styles
 * @param {String} iconName - name of icon to show
 */
const CtaComponent = ({
  href: hrefArg = '',
  asLink: asLinkArg = '',
  tag = 'a',
  type = 'Primary',
  overrideFunctionality = '',
  mobileOverrideText = null,
  onClick = () => {},
  children = null,
  className = '',
  iconName = null,
}) => {
  const themeType = type.toLowerCase();
  const StyledCtaByType = getStyledCtaByType(themeType);

  const renderAdStudioLoginCta = () => {
    const [
      { isLoggedIn, locale, hasAdAccountData, isLoading },
    ] = useAppContext();
    const { t } = useTranslation();
    const lang = LANG_MAP[locale] || LANG_MAP.default;
    if (isLoading.userData) {
      return (
        <Styled.Button href={hrefArg} onClick={event => event.preventDefault()}>
          <ActivityIndicator modifier={ActivityIndicator.MODIFIERS.INLINE} />
        </Styled.Button>
      );
    }
    return isLoggedIn ? (
      <StyledCtaByType
        href={`${BROWSE_ADS}?lang=${lang}`}
        rel={COMMON_LINK_PROPS.NO_OPENER}
        target={COMMON_LINK_PROPS.TARGET_BLANK}
        onClick={event => {
          mParticle.logEvent(
            'ads_manager_redirect_complete',
            mParticle.EventType.Other,
          );
          onClick(event);
        }}
      >
        {hasAdAccountData ? t('manageAds') : t('createAnAd')}
      </StyledCtaByType>
    ) : (
      <Styled.Button
        onClick={event => {
          event.preventDefault();
          const { hostName = '' } = getURL();
          const redirectURI = `${hostName}/${AUTH_PAGE}`;
          const scope = 'user-read-private user-read-email';
          const state = getRandomHash(`${locale}.`);
          const authURL = `${SPOTIFY_AUTHORIZE}?response_type=token&client_id=${publicRuntimeConfig.SPOTIFY_CLIENT_ID}&scope=${scope}&redirect_uri=${redirectURI}&state=${state}`;
          localStorage.setItem(REDIRECT_STATE, state);
          window.location.assign(authURL);
          onClick(event);
        }}
      >
        {mobileOverrideText || children}
      </Styled.Button>
    );
  };

  const renderLeadGenFormCta = () => {
    const [appState, appDispatch] = useAppContext();
    const { t } = useTranslation();
    return (
      <Styled.Button
        onClick={event => {
          const { isModalFormOpen } = appState;

          appDispatch({
            type: ACTION_TYPES.MODAL_FORM_TOGGLE,
            status: !isModalFormOpen,
          });
          appDispatch({
            type: ACTION_TYPES.NAVIGATION_TOGGLE,
            status: false,
          });

          // eslint-disable-next-line no-param-reassign
          event.data = { isModalFormOpen };
          onClick(event);
        }}
      >
        {t('letsTalk')}
      </Styled.Button>
    );
  };

  switch (overrideFunctionality) {
    case CTA_OVERRIDES.ADSTUDIO_LOGIN:
      return renderAdStudioLoginCta();
    case CTA_OVERRIDES.STICKY_FORM:
      return renderLeadGenFormCta();
    default:
      break;
  }

  const onLinkClick = useCallback(
    event => {
      /**
       * This is a global track for all anchor tags, even if they implement their own track event
       * in that case two events are fired and that's expected
       * Buttons and other non anchors must implement their own event track
       */
      if (tag === 'a') {
        const parentElement = event.target.parentElement?.className;
        const elementParts =
          typeof parentElement === 'string' && parentElement?.split('styled');
        const componentLocation = elementParts[0]?.trim();
        mParticle.logEvent('button_click', mParticle.EventType.Navigation, {
          click_text: event.target.innerText,
          click_location: componentLocation || event.target.className,
        });
      }
      onClick(event);
    },
    [onClick],
  );

  return (
    <StyledCtaByType
      href={asLinkArg || hrefArg}
      onClick={onLinkClick}
      className={className}
      locale={false}
    >
      {themeType !== CTA_TYPES.WRAPPER && children}
      {iconName && <Icon name={iconName} className="cta-icon" />}
    </StyledCtaByType>
  );
};

CtaComponent.propTypes = {
  /**
   * Url of the link or dynamic definition in case of dynamic link
   */
  href: PropTypes.string,
  /**
   * Next Link as prop
   */
  asLink: PropTypes.string,
  /**
   * HTML tag override in case of a non anchor cta
   */
  tag: PropTypes.string,
  /**
   * CTA type: primary or secondary
   */
  type: PropTypes.string,
  /**
   * override functionality for AdStudio Login and Lead Gen Form
   */
  overrideFunctionality: PropTypes.string,
  /**
   * mobile text for AdStudio Login and Lead Gen Form
   */
  mobileOverrideText: PropTypes.string,
  /**
   * on click handler
   */
  onClick: PropTypes.func,
  /**
   * React children node
   */
  children: PropTypes.node,
  /**
   * Class to override current styles
   */
  className: PropTypes.string,
  /**
   * Name of icon to show
   */
  iconName: PropTypes.string,
};

export default CtaComponent;
