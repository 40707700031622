import styled from 'styled-components';

import { fontWeights } from 'styles/variables';
import { maxWidth, minWidth } from 'styles/media-queries';
import { SocialIcons } from 'components/molecules/social-icons';
import { Icon } from 'components/atoms';
import { black, darkGray, gray, white } from 'styles/colors';
import { spacer1, spacer4, spacer5 } from 'styles/spacers';

export const Container = styled.div`
  position: relative;
  margin-top: ${spacer4};

  display: flex;
  flex-direction: row;
  align-items: center;

  ${maxWidth.sm`
    flex-direction: column;
    justify-content: flex-start;
  `}
`;

export const Byline = styled.div`
  overflow-wrap: break-word; /* Fallback for safari */
  overflow-wrap: anywhere;
  width: 29rem;
  margin-left: 2rem;
  border-right: 0.5px solid ${props => (props.isDarkTheme ? gray : darkGray)};
  text-align: start;

  ${maxWidth.sm`
   border-right: none;
   margin-left: auto;
  `}
`;

export const PublishDate = styled.span`
  letter-spacing: -0.05rem;
  margin-bottom: 3.2rem;
  color: ${props => (props.isDarkTheme ? gray : darkGray)};

  ${minWidth.lg`
    margin-bottom: 4rem;
  `}
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  ${maxWidth.sm`
    margin-right: auto;
  `}
`;

export const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${spacer5};

  p {
    margin-right: 2rem;
    font-weight: ${fontWeights.bold};
  }

  ${maxWidth.sm`
      justify-content: start;
      margin-right: auto;
      margin-top: ${spacer1};

      p {
        margin-left: -${spacer4};
      }
   `}
`;

export const Social = styled(SocialIcons)`
  path {
    fill: ${props => (props.isDarkTheme ? white : black)};
  }
`;

export const Logo = styled(Icon)`
  svg {
    height: 6rem;
    width: 6rem;

    ${maxWidth.sm`
      margin-top: -0.5rem;
      transform: scale(0.7);
    `}
  }

  path {
    fill: ${props => (props.isDarkTheme ? white : black)};
  }
`;
