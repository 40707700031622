import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import mParticle from '@mparticle/web-sdk';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { Headline, Cta, Video } from 'components/atoms';
import { Byline } from 'components/organisms';
import { CTA_TYPES } from 'constants/cta-types';
import * as CommonTypes from 'constants/common-proptypes';
import { useAppContext } from 'contexts/app-context';

import { getLinkProps } from 'utils/get-link-props';
import { MODULES_MULTI_CTA } from 'utils/google-tag-manager';
import { useImageSupport } from 'utils/use-image-support';
import {
  ACTIVATE_ANIMATION_CLASS,
  SETUP_ANIMATION_CLASS,
} from 'styles/animations';
import { black, white } from 'styles/colors';
import * as Styled from './Hero.styled';

function getRoute(tagName, locale) {
  let tagRoute;
  switch (tagName.toLowerCase()) {
    case 'articles':
      tagRoute = 'news-and-insights';
      break;
    case 'news':
      tagRoute = 'news-and-inspiration';
      break;
    case 'success stories':
      tagRoute = 'inspiration';
      break;
    default:
      tagRoute = tagName
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-');
  }
  return ` /${locale}/${tagRoute}`;
}

function getTagButtons(tags) {
  const primaryTag = tags?.filter(
    tag => tag.id.includes('articles') || tag.id.includes('successStories'),
  );

  let filteredTags = tags?.filter(topic => topic.id.startsWith('topic'));
  filteredTags = filteredTags.map(topic =>
    topic.id.startsWith('topic') ? topic.name.split(':')[1].trim() : topic.name,
  );

  primaryTag.forEach(tag => filteredTags.unshift(tag.name));

  return filteredTags;
}

/**
 * Hero Component
 * @param {object} props.hero - hero data
 * @param {boolean} props.hasLowerNav - flag if page has lower navigation
 * @param {Array} props.tags - page tags
 * @param {string} props.eyebrow - page eyebrow
 * @param {object} props.author - author data
 * @param {string} props.publishDate - page publish date
 * @returns {ReactComponent}
 */
const Hero = ({
  hero,
  hasLowerNav,
  tags,
  eyebrow,
  author = null,
  publishDate,
}) => {
  const { locale } = useRouter();

  // hero data
  const title = get(hero, 'title', '');
  const description = get(hero, 'description', '');
  const titleColor = get(hero, 'titleColor') || white;
  const backgroundColor = get(hero, 'backgroundColor') || black;
  const ctaItems = get(hero, 'ctasCollection.items', []);
  const video = get(hero, 'video') || null;
  const videoRef = useRef(null);
  const image = get(hero, 'foregroundImage') || null;
  const { queryUrl } = useImageSupport();
  const textAlign = get(hero, 'textAlign') || 'left';
  const mediaAlign = get(hero, 'mediaAlign') || 'right';
  const hasMedia = video !== null || image !== null;
  const fullBleed = hasMedia && mediaAlign === 'full';
  const eyebrowText = eyebrow || get(hero, 'eyebrow');
  const filteredTags = tags && getTagButtons(tags);

  // animation
  const [startAnimation, setStartAnimation] = useState(false);
  const [{ isAntiFlickerActive }] = useAppContext();
  useEffect(() => {
    if (!isAntiFlickerActive) {
      setStartAnimation(true);
    }
  }, [isAntiFlickerActive]);
  const animationClass = classNames(SETUP_ANIMATION_CLASS, {
    [ACTIVATE_ANIMATION_CLASS]: startAnimation,
  });

  // mparticle CTA click handler
  const onCtaClick = () => {
    mParticle.logEvent('action', mParticle.EventType.Other, {
      action_text: 'hero button click',
      action_location: MODULES_MULTI_CTA.heroDynamic,
    });
  };

  return (
    <ThemeProvider
      theme={{
        titleColor,
        backgroundColor,
        textAlign,
        hasText: title || description,
        mediaAlign,
        hasMedia,
        hasLowerNav,
        fullBleed,
      }}
    >
      <Styled.Header>
        {fullBleed && (
          <Styled.FullBleedContainer className={animationClass}>
            {image && <Styled.Image url={image[queryUrl]} />}
            {video && video.heroVideo && (
              <Styled.HeroVideoContainer>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  ref={videoRef}
                  muted
                  autoPlay
                  loop
                  playsInline
                  controls={false}
                >
                  <source src={video.heroVideo.url} type="video/mp4" />
                </video>
              </Styled.HeroVideoContainer>
            )}
          </Styled.FullBleedContainer>
        )}
        <Styled.Container>
          {(title || description) && (
            <Styled.TextContainer>
              {filteredTags && filteredTags.length > 0 && (
                <Styled.TagsContainer className={animationClass}>
                  {filteredTags.map(tagName => (
                    <Cta
                      key={`Button-${tagName}`}
                      className="tag-button"
                      type={CTA_TYPES.ARTICLE_TAG}
                      href={getRoute(tagName, locale)}
                    >
                      {tagName}
                    </Cta>
                  ))}
                </Styled.TagsContainer>
              )}
              {eyebrowText && (
                <Styled.EyebrowText className={animationClass}>
                  {eyebrowText}
                </Styled.EyebrowText>
              )}
              {title && (
                <Headline
                  className={animationClass}
                  styling="h1"
                  text={title}
                />
              )}
              {description && (
                <Styled.Description
                  className={animationClass}
                  styling="p1"
                  text={description}
                />
              )}
              {ctaItems && ctaItems.length > 0 && (
                <Styled.CtaContainer className={animationClass}>
                  {ctaItems.map(
                    ({ title: ctaTitle, url, type, overrideFunctionality }) => {
                      const { href, asLink } = getLinkProps(url);
                      return (
                        <Cta
                          key={ctaTitle}
                          type={
                            type === CTA_TYPES.SECONDARY
                              ? CTA_TYPES.TERTIARY
                              : type
                          }
                          href={href}
                          asLink={asLink}
                          overrideFunctionality={overrideFunctionality}
                          onClick={onCtaClick}
                        >
                          {ctaTitle}
                        </Cta>
                      );
                    },
                  )}
                </Styled.CtaContainer>
              )}
              {author && (
                <Styled.BylineContainer className={animationClass}>
                  <Byline
                    author={author}
                    publishDate={publishDate}
                    isDarkTheme={titleColor === white}
                  />
                </Styled.BylineContainer>
              )}
            </Styled.TextContainer>
          )}
          {hasMedia && !fullBleed && (
            <Styled.MediaContainer className={animationClass}>
              {image && (
                <Styled.Image url={image[queryUrl]} className="media" />
              )}
              {video &&
                (video.heroVideo ? (
                  <Styled.HeroVideoContainer className="media">
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      ref={videoRef}
                      muted
                      autoPlay
                      loop
                      playsInline
                      controls={false}
                    >
                      <source src={video.heroVideo.url} type="video/mp4" />
                    </video>
                  </Styled.HeroVideoContainer>
                ) : (
                  <Video
                    className="video media"
                    videoId={video.videoId}
                    type={video.type}
                    videoName={video.name}
                    playing={false}
                    controls
                    muted={false}
                    loop
                    showCaptions
                  />
                ))}
            </Styled.MediaContainer>
          )}
        </Styled.Container>
      </Styled.Header>
    </ThemeProvider>
  );
};

Hero.propTypes = {
  /**
   * Hero data
   */
  hero: PropTypes.shape({
    /**
     * The heading title
     */
    title: PropTypes.string.isRequired,
    /**
     * The color of the title
     */
    titleColor: PropTypes.string,
    /**
     * The foreground image
     */
    foregroundImage: CommonTypes.Image,
    /**
     * Color used as background behind text
     */
    backgroundColor: PropTypes.string,
    /**
     * The component video, composed by the url and description.
     */
    video: PropTypes.shape({
      videoId: PropTypes.string,
      videoName: PropTypes.string, // The video name set by author
      type: PropTypes.string, // The video player <Youtube | Vimeo>
      caption: PropTypes.string, // The video caption set by author
      controls: PropTypes.bool, // Shows or hide video player controls
    }),
    /**
     * Text alignment
     */
    textAlign: PropTypes.string,
    /**
     * Media alignment
     */
    mediaAlign: PropTypes.string,
    /**
     * Margin styling
     */
    margin: PropTypes.bool,
  }),
  /**
   * Flag if page has lower navigation
   */
  hasLowerNav: PropTypes.bool,
  /**
   * The page tags
   */
  tags: PropTypes.arrayOf(PropTypes.any),
  /**
   * The page eyebrow
   */
  eyebrow: PropTypes.string,
  /**
   * The author data
   */
  author: PropTypes.objectOf(PropTypes.any),
  /**
   * The publish date
   */
  publishDate: PropTypes.string,
};

export default Hero;
