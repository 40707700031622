import styled, { css } from 'styled-components';

import { Eyebrow, Headline } from 'components/atoms';
import { getThemeColor, THEME_SELECTOR_TYPES } from 'utils/get-theme-color';
import { columnsGutter, container } from 'styles/grid';
import { minWidth } from 'styles/media-queries';
import { Markdown } from 'components/molecules';

import { black, white } from 'styles/colors';
import { spacer16, spacer24, spacer80, spacer64 } from 'styles/spacers';
import {
  ACTIVATE_ANIMATION_CLASS,
  fadeContentSlideUp,
} from 'styles/animations';

// Styled components
export const FeatureCard = styled.section.attrs(props => ({
  id: props.id,
}))`
  ${({ theme }) => !theme.fullWidth && container}

  ${({ theme }) =>
    theme.themeSelectorType === THEME_SELECTOR_TYPES.GET_STARTED &&
    `
    ${fadeContentSlideUp.setup}
    &.${ACTIVATE_ANIMATION_CLASS} {
      ${fadeContentSlideUp.play}
      animation-delay: 700ms;
    }
  `}

  color: ${({ theme }) => getThemeColor(theme.themeKey)};
`;

export const Container = styled.div`
  padding: ${({ theme }) =>
    theme.themeSelectorType === THEME_SELECTOR_TYPES.AD_FORMAT
      ? `0`
      : `${spacer24} ${spacer16}`};
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.backgroundColor || black};
  display: flex;
  flex-direction: column;
  gap: ${spacer24};

  ${minWidth.lg`
    ${columnsGutter}
    padding: ${({ theme }) =>
      theme.themeSelectorType === THEME_SELECTOR_TYPES.AD_FORMAT
        ? `0`
        : `${spacer80} ${spacer64}`};
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    ${({ theme }) =>
      theme.hasImage &&
      `
      align-items: center;
    `}
  `}
`;

export const leftSide = css`
  grid-column: 1 / span 6;
  grid-row: 1;
`;

export const rightSide = css`
  grid-column: 7 / span 6;
  grid-row: 1;
`;

export const ImageContainer = styled.div`
  ${({ theme }) => theme.imageTop && `order: -1;`}
  &.hovering {
    &::before {
      transform: scale(1.1);
    }
  }

  ${minWidth.lg`
    ${({ theme }) => (theme.imageRight ? rightSide : leftSide)}
  `}
`;

export const Content = styled.div`
  ${minWidth.lg`
    ${columnsGutter}

    display: grid;
    grid-column: span 12;
    grid-template-columns: repeat(12, 1fr);

    ${({ theme }) =>
      theme.hasImage &&
      `
      display: flex;
      flex-direction: column;
      ${theme.imageRight ? leftSide : rightSide}
    `}
  `}
`;

export const HeadlineContainer = styled.div`
  ${minWidth.lg`
    ${leftSide} 
  `}
`;

export const ColumnList = styled(props => <Markdown {...props} />)`
  margin: 2.4rem 0 5rem 0;
`;

export const TitleEyebrow = styled(Eyebrow)`
  margin-bottom: 0.8rem;

  ${minWidth.lg`
    margin-bottom: 2.4rem;
  `}
`;

export const TitleNumberEyebrow = styled(Eyebrow)`
  margin-bottom: 1.6rem;
  background-color: ${white};
  color: ${black};
  width: 4rem;
  height: 4rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 50%;
  font-weight: 1000;

  ${minWidth.lg`
    width: 4.6rem;
    height: 4.6rem;
  `}
`;

export const Title = styled(Headline)`
  color: ${({ theme }) => theme.titleColor || getThemeColor(theme.themeKey)};
`;

export const DescriptionContainer = styled.div`
  margin-top: ${spacer16};
  display: flex;
  flex-direction: column;
  gap: ${spacer16};

  ${minWidth.lg`
    ${({ theme }) => !theme.hasImage && `margin-top: 0;`}
    grid-column: 8 / span 5;
    gap: ${spacer24};
  `}

  li {
    font-weight: bold;
  }
`;

export const CtasContainer = styled.div`
  display: flex;
  gap: 1.2rem;
`;
