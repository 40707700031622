/**
 * Cta types
 */
export const CTA_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  TEXT_LINK: 'textlink',
  WRAPPER: 'wrapper',
  ARTICLE_TAG: 'articletag',
};
