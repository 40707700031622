import styled, { css } from 'styled-components';
import { darkGray } from 'styles/colors';
import { container } from 'styles/grid';
import { minWidth } from 'styles/media-queries';
import { spacer8, spacer16, spacer24 } from 'styles/spacers';
import { fontWeights, lowerNavHeight, navHeight } from 'styles/variables';
import { Icon } from 'components/atoms';

/* Styled Components */
export const Header = styled.header`
  position: fixed;
  top: ${({ theme }) => (theme.showNav ? `${navHeight.smToLg}rem` : `2rem`)};
  width: 100%;
  z-index: 3;
  transition: top 0.5s ease-in-out;
`;

export const Container = styled.div`
  ${container}
  padding-right: 0;

  ${minWidth.md`
    ${container}
  `}
`;

export const BarContainer = styled.div`
  background-color: ${darkGray};
  color: ${({ theme }) => theme.textColor};
  border-radius: 6rem 0 0 6rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: ${lowerNavHeight.sm}rem;
  width: 100%;
  padding: 0 ${spacer24};
  gap: 1rem;
  overflow: scroll;
  box-shadow: 1px 4px 16px 1px rgb(0 0 0 / 30%);

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  ${minWidth.sm`
    border-radius: 6rem;
    overflow: hidden;
    gap: ${spacer16};
  `}

  ${minWidth.lg`
    height: ${lowerNavHeight.mlUp}rem;
  `}
`;

const LOWER_NAV_TEXT = css`
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.textColor};
`;

export const ExploreContainer = styled.div`
  ${LOWER_NAV_TEXT}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${spacer8};
  color: ${({ theme }) => theme.highlightColor};
  font-weight: ${fontWeights.extra_bold};
`;

export const Arrow = styled(Icon)`
  height: 1.6rem;
  width: 1.6rem;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  path {
    fill: ${({ theme }) => theme.highlightColor};
  }
`;

export const LowerNavContainer = styled.nav`
  display: flex;
  flex-direction: row;
  gap: ${spacer16};
`;
